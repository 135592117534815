import firebase from "@/firebase";

const selectionService = {
  async create(newSelection) {
    const response = await firebase
      .firestore()
      .collection("selection2025")
      .add(newSelection);
    if (response) {
      return response.id;
    } else {
      return "";
    }
  },

  async set(payload) {
    let response = await firebase
      .firestore()
      .collection("selection2025")
      .doc(payload.id)
      .set({ ...payload.candidate }, { merge: true });
    if (response) {
      return true;
    } else {
      return false;
    }
  },

  async list(payload) {
    let response;
    if (payload && payload.length > 0) {
      response = await firebase
        .firestore()
        .collection("selection2025")
        .where("school.segment", "==", payload)
        .get();
    } else {
      response = await firebase.firestore().collection("selection2025").get();
    }

    if (response) {
      let selectionList = [];
      response.forEach((doc) => {
        let selection = doc.data();
        selection.id = doc.id;
        selectionList.push(selection);
      });
      return selectionList;
    } else {
      return [];
    }
  },
};

export default selectionService;
