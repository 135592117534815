import { render, staticRenderFns } from "./SelectionCreateTemplate.vue?vue&type=template&id=ccef8a82&scoped=true&"
import script from "./SelectionCreateTemplate.vue?vue&type=script&lang=js&"
export * from "./SelectionCreateTemplate.vue?vue&type=script&lang=js&"
import style0 from "./SelectionCreateTemplate.vue?vue&type=style&index=0&id=ccef8a82&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccef8a82",
  null
  
)

export default component.exports