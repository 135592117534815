import router from "../router";

const indexPlugin = (store) => {
  store.subscribeAction({
    after: (action) => {
      switch (action.type) {
        case "selection/create": {
          store.dispatch("loading", false);
          store.dispatch("public/reset");
          if (router.currentRoute != "/erro") {
            router.push("/obrigado");
          }
          break;
        }

        case "auth/login": {
          // let provider = action.payload.email;
          let filter = "";
          switch (store.state.auth.currentAuth.email) {
            case "lit2021infantil@gmail.com":
              filter = "infantil";
              break;
            case "lit2021fundamental1@gmail.com":
              filter = "fundamental1";
              break;
            case "lit2021fundamental2@gmail.com":
              filter = "fundamental2";
              break;
            case "lit2021medio@gmail.com":
              filter = "medio";
              break;
            default:
              filter = "fundamental2";
          }

          store.dispatch("auth/setSegment", filter);
          store.dispatch("loading", false);
          router.push("/admin");
          break;
        }
        case "auth/setAuth": {
          if (store.state.auth.currentSegment === undefined) {
            let filter = "";

            switch (store.state.auth.currentAuth.email) {
              case "lit2021infantil@gmail.com":
                filter = "infantil";
                break;
              case "lit2021fundamental1@gmail.com":
                filter = "fundamental1";
                break;
              case "lit2021fundamental2@gmail.com":
                filter = "fundamental2";
                break;
              case "lit2021medio@gmail.com":
                filter = "medio";
                break;
              default:
                filter = "medio";
            }
            store.dispatch("auth/setSegment", filter);
          } else {
            store.dispatch("loading", false);
          }

          break;
        }
        case "auth/setSegment": {
          store.dispatch("selection/list", action.payload);
          break;
        }
        case "selection/list": {
          store.dispatch("loading", false);
          break;
        }
      }
    },
  });
};
export default indexPlugin;
