import selectionStore from "./selection";
import authStore from "./auth";
import publicStore from "./public";

const modules = {
  public: publicStore,
  auth: authStore,
  selection: selectionStore,
};

export default modules;
