<script>
// import CandidateOrder from "@/components/molecules/CandidateOrder.vue";
import AppInput from "@/components/atoms/AppInput.vue";
import AppButton from "@/components/atoms/AppButton.vue";
import AppMenu from "@/components/atoms/AppMenu.vue";
import CandidateList from "@/components/organisms/CandidateList.vue";
import AppDialog from "@/components/atoms/AppDialog.vue";
import TheToolbar from "@/components/organisms/TheToolbar.vue";

import { getSegmentLevels } from "@/helpers/get-segment-levels";

import ModularCard from "@/components/molecules/ModularCard.vue";

export default {
  name: "CandidateListTemplate",
  components: {
    AppInput,
    AppButton,
    AppDialog,
    AppMenu,
    // CandidateOrder,
    CandidateList,
    TheToolbar,
    ModularCard,
  },
  props: {
    candidateList: { type: Array },
    currentTab: { type: String },
  },
  created() {
    this.segmentItens = getSegmentLevels(this.$store.state.auth.currentSegment);
    this.segmentItens.push({
      text: "Todos",
      value: "all",
    });
  },
  data() {
    return {
      currentSorting: "createdAt",
      currentFilter: "all",
      dateFilter: null,
      segmentFilter: "all",
      searchText: "",
      segmentItens: [],
      dialog: {
        confirm: false,
        tempEvent: {},
      },
      noCandidateListCard: {
        0: {
          type: "title",
          title: "Nenhum candidato por aqui.",
        },
        1: {
          type: "image",
          image: "no-members",
        },
        2: {
          subtitle: "Seu segmento ainda não possui candidatos cadastrados.",
          type: "subtitle",
        },
        3: {
          type: "subtitle",
          subtitle: "Volte mais tarde. ;)",
        },
      },
    };
  },
  methods: {
    // Emited events
    clickEnem(event) {
      this.$emit("clickEnem", event);
    },
    clickGrade(event) {
      this.$emit("clickGrade", event);
    },
    clickIdentityFront(event) {
      this.$emit("clickIdentityFront", event);
    },
    clickIdentityBack(event) {
      this.$emit("clickIdentityBack", event);
    },
    clickPdf(event) {
      this.$emit("clickPdf", event);
    },
    goBack(event) {
      this.$emit("goBack", event);
    },
    clickProfile(event) {
      this.$emit("clickProfile", event);
    },
    fabClick() {
      this.$emit("fabClick");
    },
    emit(payload) {
      this.searchText = payload.value;
    },
    clickLogout() {
      this.$emit("clickLogout");
    },

    changeSorting(payload) {
      this.currentSorting = payload;
    },
    changeFilter(payload) {
      this.currentFilter = payload;
    },
    // Local Methods
    search(list) {
      if (this.searchText.length > 0 && list) {
        return list.filter((candidate) =>
          candidate.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        return list;
      }
    },
    dialogConfirm() {
      this.dialog.confirm = false;
      this.$emit("clickPdf", this.dialog.tempEvent);
    },
    dialogCancel() {
      this.dialog.confirm = false;
    },
    dialogOpen(payload) {
      let list = this.candidateList;
      if (
        list.find((candidate) => candidate.id === payload).pdfUrl === undefined
      ) {
        this.dialog.tempEvent = payload;
        this.dialog.confirm = true;
      } else {
        this.$emit("clickPdf", payload);
      }
    },
    changeSegment(payload) {
      this.segmentFilter = payload;
    },
    changeTab(payload) {
      this.$emit("changeTab", payload);
    },
    changeDate(payload) {
      this.dateFilter = payload.value;
    },
  },
  computed: {
    currentSegment: function () {
      return this.$store.state.auth.currentSegment;
    },
    filteredCandidates: function () {
      return this.search(this.candidateList);
    },
    dateFilterFormatted: function () {
      return (
        this.dateFilter.substring(8) + "/" + this.dateFilter.substring(5, 7)
      );
    },
  },
};
</script>

<template>
  <section id="candidate-list-template" class="section-container">
    <the-toolbar
      title="Seleção 2025"
      subtitle="Lista de Candidatos"
      :isForm="false"
      icon="mdi-logout"
      @click="clickLogout"
    />
    <span v-if="candidateList.length == 0" style="width: 100%">
      <modular-card
        :cardModules="noCandidateListCard"
        customStyle="min-height: calc((var(--vh, 1vh) * 100) - 192px) !important; justify-content: space-between;"
      >
      </modular-card>
    </span>
    <span
      id="candidate-list-content"
      v-if="candidateList.length > 0"
      style="width: 100%"
    >
      <span
        id="candidate-list-filters"
        :style="currentTab === 'selection' ? 'gap: 16px' : 'gap: 4px'"
      >
        <span id="candidate-list-actions">
          <app-input
            name="search"
            inputType="search"
            icon="mdi-magnify"
            label="Buscar"
            :value="searchText"
            :customClass="currentTab === 'selection' ? '' : 'border-20'"
            @change="emit"
          />
          <app-menu
            v-if="currentTab === 'selection'"
            title="Segmentos"
            color="white"
            textColor="#969696"
            icon="mdi-filter-variant"
            :items="segmentItens"
            :current="segmentFilter"
            customStyle="border-radius: 5px 20px 20px 5px;"
            @click="changeSegment"
          />
        </span>
        <span id="candidate-list-actions" v-if="currentTab === 'selection'">
          <app-button
            v-if="dateFilter != null"
            type="new-return"
            name="clearDate"
            icon="mdi-content-save-check-outline"
            text="Limpar"
            width="100%"
            customStyle="color: var(--white); "
            @click="changeDate({ value: null })"
          >
          </app-button>
          <app-input
            name="filter"
            text="Filtrar"
            icon="mdi-filter"
            inputType="iconDate"
            width="100%"
            :value="dateFilter"
            @change="changeDate"
            :customStyle="
              dateFilter != null
                ? 'min-height: 36px; border-radius: 5px 20px 20px 5px'
                : 'min-height: 36px;'
            "
          >
            <span
              style="
                margin-right: 12px;
                text-transform: none;
                color: rgba(0, 0, 0, 0.87);
              "
            >
              {{
                dateFilter != null ? dateFilterFormatted : "Filtrar por Data"
              }}
            </span>
          </app-input>
        </span>
        <span v-else>
          <contest-segment-filter
            :items="segmentItens"
            :current="segmentFilter"
            @click="changeSegment"
          />
        </span>
        <!-- <candidate-order
          :currentSorting="currentSorting"
          :currentFilter="currentFilter"
          @clickOrder="changeSorting"
          @clickFilter="changeFilter"
        /> -->
      </span>
      <span style="transform: translateY(-8px); width: 100%">
        <candidate-list
          :candidateList="filteredCandidates"
          :currentSorting="currentSorting"
          :dateFilter="dateFilter"
          :segmentFilter="segmentFilter"
          @clickPdf="dialogOpen"
          @clickProfile="clickProfile"
        />
      </span>
    </span>
    <app-dialog
      :dialog="dialog.confirm"
      title="Confirmação"
      text="Um PDF com os dados do Candidato será gerado ao confirmar. O tempo médio é de 1 a 2 minutos para ter acesso ao PDF."
      buttonCancel="Cancelar"
      buttonConfirm="Gerar PDF"
      @clickConfirm="dialogConfirm('confirm')"
      @clickCancel="dialogCancel('confirm')"
    />
  </section>
</template>

<style lang="scss">
.theme--light.v-list {
  background-color: transparent;
}
#candidate-list-template {
  min-height: calc((var(--vh, 1vh) * 100) - 192px);
}
.section-container {
  gap: 28px;
  padding: 16px 12px !important;
  justify-content: flex-start !important;
  width: 100vw;

  @media screen and (min-width: 370px) {
    padding: 16px !important;
  }
  @media screen and (min-width: 1200px) {
    padding: 0 !important;
    width: calc(90vw - 128px);
  }

  @media screen and (min-width: 1600px) {
    width: calc(90vw - 320px);
  }
}

#candidate-list-template {
  padding-bottom: 96px;
}

#candidate-list-content {
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  gap: 32px;
}

#candidate-list-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  #candidate-list-actions {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  @media screen and (min-width: 1200px) {
    width: 60%;
    transform: translateY(8px);
    margin-left: auto;
    flex-direction: row-reverse;
  }
}
</style>
