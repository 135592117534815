<script>
import format from "@/helpers/format";

export default {
  name: "CandidateSchoolInfo",
  props: {
    birthday: {
      type: String,
    },
    createdAt: {
      type: Object,
    },
    period: {
      type: String,
    },
    originSchool: {
      type: String,
      default: "--",
    },
    nextGrade: {
      type: String,
    },
  },
  computed: {
    age: function () {
      var birthday = this.birthday;
      var ageDifMs = Date.now() - Date.parse(birthday);
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      let result = Math.abs(ageDate.getUTCFullYear() - 1970);
      return result;
    },
    formatedBirthday: function () {
      return format.date(Date.parse(this.birthday));
    },
    formatedCreatedAt: function () {
      return (
        format.date(this.createdAt.toDate()) +
        " - " +
        format.time(this.createdAt.toDate())
      );
    },
  },
};
</script>

<template>
  <div class="candidate-school-info">
    <span class="candidate-school-info__row">
      <p class="candidate-school-info__title">
        <small>
          <strong>Série em 2025: </strong>
        </small>
      </p>
      <p class="candidate-school-info__data">
        <small>
          {{ nextGrade + " | " + period }}
        </small>
      </p>
    </span>
    <span class="candidate-school-info__row">
      <p class="candidate-school-info__title">
        <small>
          <strong>Escola de Origem: </strong>
        </small>
      </p>
      <p class="candidate-school-info__data">
        <small>
          {{ originSchool }}
        </small>
      </p>
    </span>
    <span class="candidate-school-info__row">
      <p class="candidate-school-info__title">
        <small>
          <strong>Data de Nascimento: </strong>
        </small>
      </p>
      <p class="candidate-school-info__data">
        <small>
          {{ formatedBirthday + " - " + age + " anos" }}
        </small>
      </p>
    </span>
    <span class="candidate-school-info__row">
      <p class="candidate-school-info__title">
        <small>
          <strong>Data de Cadastro: </strong>
        </small>
      </p>
      <p class="candidate-school-info__data">
        <small>
          {{ formatedCreatedAt }}
        </small>
      </p>
    </span>
  </div>
</template>

<style lang="scss" scoped>
.candidate-school-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  // flex-direction: column;
  justify-content: center;

  p {
    margin: 0px !important;
  }

  .candidate-school-info__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;

    .candidate-school-info__title {
      width: calc(48% - 6px);
    }

    .candidate-school-info__data {
      width: calc(52% - 6px);
    }
    p {
      margin: 2px 0 !important;
      font-weight: 600;
      font-size: var(--font-medium);

      small {
        font-size: 13px;
        font-weight: 500;
        color: var(--gray-dark);
      }
    }
    @media screen and (min-width: 400px) {
      gap: 12px;
      p {
        small {
          font-size: var(--font-smaller);
        }
      }
    }
  }

  /* @media screen and (min-width: 1200px) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    } */
}
</style>
