<script>
import axios from "axios";
import SelectionCreateTemplate from "@/components/templates/SelectionCreateTemplate.vue";
import { convertCreateFormToSelection } from "@/functions/selection/convertCreateFormToSelection";

export default {
  components: { SelectionCreateTemplate },
  name: "SelectionCreatePage",
  data: () => ({
    addressQuery: {
      street: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    },
    step: 1,
    form: {},
    dialog: {
      birthday: false,
    },
    show: true,
    formMode: "new",
    currentCep: {
      type: String,
      default: "",
    },
  }),
  created() {
    this.addressQuery = {
      street: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    };
  },
  props: {
    segment: {
      type: String,
      default: "",
    },
  },
  methods: {
    // TODO: Move to service
    // Call from WHERE?
    // How to feed this info?
    async checkCEP(payload) {
      let pureCep = payload.replaceAll("-", "");
      if (pureCep.length == 8) {
        if (this.currentCep != pureCep) {
          this.currentCep = pureCep;
          this.$store.dispatch("loading", true);
          try {
            await axios
              .get("https://viacep.com.br/ws/" + pureCep + "/json/")
              .then(async (response) => {
                if (response != undefined && !response.data["erro"]) {
                  this.addressQuery.street = response.data.logradouro;
                  this.addressQuery.complement = response.data.complemento;
                  this.addressQuery.neighborhood = response.data.bairro;
                  this.addressQuery.city = response.data.localidade;
                  this.addressQuery.state = response.data.uf;
                  this.addressQuery.cep = response.data.cep;
                }
              });
          } catch (e) {
            this.$router.push("/erro");
            this.$store.dispatch("logout", { root: true });
          }
          this.$store.dispatch("loading", false);
        }
      }
    },
    async submit(payload) {
      let newPayload = convertCreateFormToSelection(payload);
      await this.$store.dispatch("selection/create", newPayload);
    },
  },
};
</script>

<template>
  <selection-create-template
    :segment="segment"
    :addressQuery="addressQuery"
    @checkCEP="checkCEP"
    @submit="submit"
  />
</template>

<style lang="scss" scoped>
.container,
form {
  min-height: 100vh;
  min-width: 100%;
}
</style>
