<script>
import HomeTemplate from "@/components/templates/HomeTemplate.vue";

export default {
  components: { HomeTemplate },
  name: "HomePage",
  mounted() {
    this.$store.dispatch("public/reset", { root: true });
  },
  methods: {
    async click(payload) {
      this.$router.push("/selecao/" + payload);
    },
  },
};
</script>

<template>
  <home-template @click="click" />
</template>

<style lang="scss" scoped>
.container,
form {
  min-height: 100vh;
  min-width: 100%;
}
</style>
