<script>
import SegmentButton from "@/components/molecules/SegmentButton.vue";
import AppImage from "@/components/atoms/AppImage.vue";

export default {
  components: { SegmentButton, AppImage },
  name: "HomeTemplate",

  methods: {
    async click(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="header">
      <h2>Processo Seletivo 2025</h2>
      <!-- logo <app-image></app-image> -->
      <app-image
        image="logo-white"
        customStyle="transform: translateX(-6px); max-width: 278px; width: 80%;"
      />
      <span class="text-container">
        <p class="text">Inscrições abertas para novos alunos!</p>
        <p class="text">Selecione o formulário de interesse:</p>
      </span>
    </div>
    <div class="segment-buttons">
      <segment-button
        text="Educação Infantil e 1º ano"
        gradient="--purple-gradient"
        @click="click('infantil')"
        customClass="btn-segments"
      />
      <segment-button
        text="Ensino Fundamental Anos Iniciais"
        gradient="--wine-gradient"
        @click="click('fundamental1')"
        customClass="btn-segments"
      />
      <segment-button
        text="Ensino Fundamental Anos Finais"
        gradient="--primary-gradient2"
        @click="click('fundamental2')"
        customClass="btn-segments"
      />
      <segment-button
        text="Ensino Médio"
        gradient="--green-gradient"
        @click="click('medio')"
        customClass="btn-segments"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container,
form {
  min-height: 100vh;
  min-width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 8px;

  @media screen and (min-height: 800px) {
    justify-content: center;
    margin-top: 16px;
    gap: 24px;
  }
  @media screen and (min-width: 700px) {
    justify-content: center;
    margin-top: 16px;
    gap: 77px;

    h2 {
      font-size: 2.6em;
    }
  }
  .header {
    padding: 24px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    gap: 32px;

    @media screen and (min-height: 800px) {
      padding: 24px 0px 0px 0px;
      gap: 40px;
    }
    @media screen and (min-width: 700px) {
      gap: 77px;
    }
    h2,
    h5 {
      font-weight: bold;
    }

    .text {
      font-size: 18px;
      font-weight: 600;
      @media screen and (min-width: 410px) {
        font-size: 20px;
      }
      @media screen and (min-width: 700px) {
        font-size: 24px;
      }
    }
    .text-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: center;
    }
    .text-small {
      font-size: 16px;
      text-align: left;
      @media screen and (min-width: 410px) {
        font-size: 18px;
      }
      @media screen and (min-width: 700px) {
        font-size: 22px;
      }
    }
  }

  .segment-buttons {
    margin: 16px 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media screen and (min-width: 700px) {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1000px;
    }
  }
}
</style>
