import turns from "@/constants/turns";

const contestCreateForm = {
  1: {
    1: {
      type: "title",
      title: "Dados da Escola",
    },
    2: {
      type: "form",
      name: "school",
      form: {
        nextGrade: {
          value: "",
          items: [],
          required: true,
          label: "Série em 2025 *",
          name: "nextGrade",
          inputType: "select",
        },
        period: {
          value: "",
          items: turns,
          required: true,
          label: "Turno *",
          name: "period",
          inputType: "select",
        },
        originSchool: {
          value: "",
          required: true,
          label: "Escola de Origem *",
          name: "originSchool",
          inputType: "text",
        },
      },
    },
    3: {
      buttons: ["previous", "next"],
      type: "buttons",
    },
  },
  2: {
    1: {
      type: "title",
      title: "Informações do Aluno",
    },
    2: {
      type: "form",
      name: "candidate",
      form: {
        name: {
          value: "",
          required: true,
          label: "Nome do Aluno *",
          name: "name",
          inputType: "text",
        },
        birthday: {
          value: "",
          label: "Data de Nascimento *",
          name: "birthday",
          inputType: "date",
          dateMin: "2005-01-01",
          dateMax: "2011-12-31",
          dateActivePicker: "YEAR",
        },
        phone: {
          value: "",
          required: true,
          keyboardType: "tel",
          label: "Telefone",
          name: "phone",
          mask: ["(##) #####-####"],
          inputType: "text",
        },
      },
    },
    3: {
      buttons: ["previous", "next"],
      type: "buttons",
    },
  },

  3: {
    1: {
      type: "title",
      title: "Informações do Responsável",
    },

    2: {
      type: "form",
      name: "responsible",
      form: {
        name1: {
          value: "",
          label: "Nome Responsável *",
          name: "name1",
        },
        cpf1: {
          value: "",
          keyboardType: "tel",
          label: "CPF *",
          mask: ["###.###.###-##"],
          name: "cpf1",
        },
        birthday1: {
          value: "",
          label: "Data de Nascimento *",
          name: "birthday1",
          inputType: "date",
          dateMin: "1930-01-01",
          dateMax: "2010-12-31",
          dateActivePicker: "YEAR",
        },
        phone1: {
          value: "",
          required: true,
          keyboardType: "tel",
          label: "Telefone *",
          name: "phone1",
          mask: ["(##) #####-####"],
          inputType: "text",
        },
        email1: {
          value: "",
          required: true,
          keyboardType: "email",
          label: "E-mail *",
          name: "email1",
          inputType: "text",
        },
      },
    },
    3: {
      buttons: ["previous", "next"],
      type: "buttons",
    },
  },

  4: {
    1: {
      type: "title",
      title: "Endereço",
    },

    2: {
      type: "form",
      name: "address",

      form: {
        cep: {
          value: "",
          keyboardType: "tel",
          label: "CEP *",
          mask: ["#####-###"],
          name: "cep",
        },
        street: {
          value: "",
          label: "Logradouro * (Rua, Avenida, Etc)",
          name: "street",
        },
        number: {
          value: "",
          keyboardType: "tel",
          label: "Número *",
          name: "number",
        },
        complement: {
          value: "",
          label: "Complemento",
          name: "complement",
        },
        neighborhood: {
          value: "",
          label: "Bairro *",
          name: "neighborhood",
        },
        city: {
          value: "",
          label: "Cidade *",
          name: "city",
        },
        state: {
          value: "",
          label: "Estado *",
          name: "state",
        },
      },
    },
    3: {
      buttons: ["previous", "next"],
      type: "buttons",
    },
  },
  5: {
    1: {
      type: "title",
      title: "Identidade Digitalizada",
    },

    2: {
      type: "form",
      name: "documentIdentity",

      form: {
        identityFront: {
          label: "Identidade Frente *",
          icon: "mdi-camera",
          inputType: "file",
          name: "identityFront",
        },
        identityBack: {
          label: "Identidade Costas *",
          icon: "mdi-camera",
          inputType: "file",
          name: "identityBack",
        },
      },
    },
    3: {
      buttons: ["previous", "next"],
      type: "buttons",
    },
  },

  6: {
    1: {
      type: "title",
      title: "Boletim Digitalizado",
    },

    2: {
      type: "form",
      name: "documentGrade",

      form: {
        grade: {
          label: "Boletim Digitalizado *",
          icon: "mdi-camera",
          inputType: "file",
          name: "grade",
        },
      },
    },
    3: {
      buttons: ["previous", "next"],
      type: "buttons",
    },
  },
  7: {
    1: {
      type: "title",
      title: "Boletim do Enem",
    },

    2: {
      type: "form",
      name: "documentEnem",

      form: {
        enem: {
          label: "Boletim do Enem (opcional)",
          icon: "mdi-camera",
          inputType: "file",
          name: "enem",
        },
      },
    },
    3: {
      buttons: ["previous", "submit"],
      type: "buttons",
    },
  },
};

export default contestCreateForm;
