<script>
import AppStepper from "@/components/atoms/AppStepper.vue";
import AppDialog from "@/components/atoms/AppDialog.vue";
import { deepCopyObject } from "@/helpers/deepCopyObject";
import { uploadImage } from "@/helpers/uploadImage";
import { getSegmentLevels } from "@/helpers/get-segment-levels";

export default {
  name: "SelectionCreateTemplate",
  components: { AppStepper, AppDialog },
  methods: {
    async submit() {
      this.$store.dispatch("loading", true, { root: true });
      this.tempUser.document = {};
      this.tempUser.school.segment = this.segment;

      if (this.imageData.identity instanceof File) {
        let identityUrl = await uploadImage(
          this.imageData.identity,
          "selection2025/identity/"
        );
        this.tempUser.document.identityUrl = identityUrl;
        delete this.tempUser.document.identity;
      }
      if (this.imageData.grade instanceof File) {
        let gradeUrl = await uploadImage(
          this.imageData.grade,
          "selection2025/grade/"
        );
        this.tempUser.document.gradeUrl = gradeUrl;
        delete this.tempUser.document.grade;
      }

      this.emitForm();
    },
    emitForm() {
      this.tempUser.siblingsInfo = this.siblings;
      this.$emit("submit", this.tempUser);
    },
    dialogConfirm(payload) {
      this.dialog[payload] = false;
      if (payload == "confirm") {
        this.submit();
      } else {
        this.$router.back();
      }
    },
    dialogCancel(payload) {
      this.dialog[payload] = false;
    },
    dialogOpen(payload) {
      this.dialog[payload] = true;
    },
    handleSiblings(payload) {
      this.siblings = payload.fields;
    },
    handleChange(payload) {
      this.tempUser = payload;
      let keys = Object.keys(this.stepList);
      let newStepList = deepCopyObject(this.stepList);
      // let newImageData = {
      //   identity: this.imageData.identity,
      //   grade: this.imageData.grade,
      // };
      if (payload.documentIdentity.identity instanceof File) {
        this.imageData.identity = payload.documentIdentity.identity;
      }
      if (this.segment != "infantil") {
        if (payload.documentGrade.grade instanceof File) {
          this.imageData.grade = payload.documentGrade.grade;
        }
      }
      // if (
      //   newImageData.identity instanceof File ||
      //   newImageData.grade instanceof File
      // ) {
      //   this.$store.dispatch("public/setSelectionImageData", newImageData, {
      //     root: true,
      //   });
      // }
      if (payload.address.cep != undefined && payload.address.cep.length > 0) {
        if (
          payload.address.cep.length === 9 &&
          payload.address.city.length === 0
        ) {
          this.$emit("checkCEP", payload.address.cep);
        }
      }
      keys.forEach((key) => {
        let fieldKeys = Object.keys(
          this.stepList[key][this.formKeys[key]].form
        );
        fieldKeys.forEach((field) => {
          newStepList[key][this.formKeys[key]].form[field].value =
            payload[this.stepList[key][this.formKeys[key]].name][field];
        });
      });
      this.$store.dispatch("public/setSelectionStepList", newStepList, {
        root: true,
      });
    },
    updateAddress(newAddress) {
      let addressKey = {};
      let newStepList = deepCopyObject(this.stepList);
      for (let addressFormKey in this.formKeys) {
        if (
          newStepList[addressFormKey][this.formKeys[addressFormKey]].name ===
          "address"
        ) {
          addressKey = addressFormKey;
        }
      }

      if (Object.keys(newAddress).length > 0) {
        let addressKeys = Object.keys(newAddress);
        addressKeys.map((key) => {
          newStepList[addressKey][this.formKeys[addressKey]].form[key].value =
            newAddress[key];
        });
      }
      this.$store.dispatch("public/setSelectionStepList", newStepList, {
        root: true,
      });

      document.activeElement.blur();
    },
    changeStep(payload) {
      this.$store.dispatch("public/setSelectionFormStep", payload, {
        root: true,
      });
    },
  },
  computed: {
    stepList() {
      return this.$store.state.public.selectionStepList;
    },
    step() {
      return this.$store.state.public.selectionFormStep || 1;
    },
  },
  props: {
    addressQuery: {
      type: Object,
      default: () => {},
    },
    segment: {
      type: String,
      default: "",
    },
  },
  watch: {
    addressQuery: {
      // watch it
      handler: function (val) {
        this.updateAddress(val);
      },
      deep: true,
      immediate: true,
    },
  },
  updated() {
    if (this.segment === "infantil") {
      if (this.step > 5 && this.imageData.identity === null) {
        this.$store.dispatch("public/setSelectionFormStep", 5, { root: true });
      }
    } else {
      if (
        this.step > 5 &&
        this.imageData.identity === null &&
        this.imageData.grade === null
      ) {
        this.$store.dispatch("public/setSelectionFormStep", 5, {
          root: true,
        });
      }
    }
  },

  created() {
    if (Object.keys(this.addressQuery).length === 0) {
      Object.assign(this.addressQuery, this.blankAddress);
    }
    if (this.segment === "infantil") {
      let newStepList = deepCopyObject(this.stepList);

      newStepList[6] = newStepList[7];
      this.formKeys[6] = this.formKeys[7];
      delete newStepList[7];
      delete this.formKeys[7];

      this.$store.dispatch("public/setSelectionStepList", newStepList, {
        root: true,
      });
    }
    this.updateAddress(this.addressQuery);
  },
  mounted() {
    this.$store.dispatch("loading", false, {
      root: true,
    });
    let newStepList = deepCopyObject(this.stepList);

    // if (this.segment != "medio") {
    newStepList[1][2].form.nextGrade.items = getSegmentLevels(this.segment);
    // } else {
    //   newStepList[1][2].form.nextGrade.items = [
    //     {
    //       value: "medio1",
    //       text: "1ª Série",
    //     },
    //     {
    //       value: "medio2",
    //       text: "2ª Série",
    //     },
    //   ];
    // }
    this.$store.dispatch("public/setSelectionStepList", newStepList, {
      root: true,
    });
  },
  data() {
    return {
      dialog: {
        cancel: false,
        confirm: false,
      },
      tempUser: {},
      imageData: {
        identity: null,
        grade: null,
      },
      blankAddress: {
        street: "",
        complement: "",
        neighborhood: "",
        city: "",
        state: "",
        cep: "",
      },
      siblings: {},
      formKeys: {
        1: "2",
        2: "2",
        3: "2",
        4: "2",
        5: "3",
        6: "2",
        7: "3",
      },
    };
  },
};
</script>

<template>
  <section id="candidate-create-container" class="section-container">
    <div class="header">
      <h2>Seja Literato<br />você também!</h2>
      <p class="text">
        Iniciamos as inscrições para o Seletivo 2025. Você tem a opção de se
        inscrever preenchendo o cadastro abaixo.
      </p>
      <span class="text-container">
        <h3>Importante:</h3>
        <p class="text-small">
          *O cadastro só será realizado após preencher todos os campos
          corretamente.
        </p>
      </span>
    </div>
    <app-stepper
      @changeStep="changeStep"
      :currentStep="step"
      :stepList="stepList"
      :formKeys="formKeys"
      @change="handleChange"
      @changeSiblings="handleSiblings"
      @cancel="dialogOpen('cancel')"
      @submit="dialogOpen('confirm')"
    />
    <app-dialog
      :dialog="dialog.cancel"
      title="Confirmação"
      text="Tem certeza que deseja cancelar? Os dados inseridos serão perdidos."
      buttonCancel="Não"
      buttonConfirm="Sim"
      @clickConfirm="dialogConfirm('cancel')"
      @clickCancel="dialogCancel('cancel')"
    />
    <app-dialog
      :dialog="dialog.confirm"
      title="Confirmação"
      text="Tem certeza que deseja finalizar o cadastro?"
      buttonCancel="Não"
      buttonConfirm="Sim"
      @clickConfirm="dialogConfirm('confirm')"
      @clickCancel="dialogCancel('confirm')"
    />
  </section>
</template>

<style lang="scss" scoped>
#candidate-create-container {
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  @media screen and (min-height: 800px) and (max-height: 1000px) {
    justify-content: space-between;
  }
  @media screen and (min-height: 1001px) {
    justify-content: space-evenly;
  }
}

.form-container,
form {
  min-width: 100%;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: white;
  gap: 24px;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 24px;

  @media screen and (min-height: 700px) {
    gap: 32px;
    padding-top: 24px;

    h2 {
      font-size: 2.2em;
    }

    h3 {
      font-size: 24px;
    }
  }
  @media screen and (min-height: 800px) {
    gap: 36px;
    padding-top: 32px;

    h2 {
      font-size: 2.6em;
    }
    h3 {
      font-size: 26px;
    }
  }
  @media screen and (min-height: 1000px), screen and (min-width: 1000px) {
    max-width: 77%;
    margin: 0 auto;
  }
  h2,
  h3 {
    font-weight: bold;
  }
  h3 {
    text-align: center;
    padding: 0;
  }
  .text {
    font-size: 18px;

    @media screen and (min-height: 700px) {
      font-size: 24px;
    }
    @media screen and (min-height: 800px) {
      font-size: 22px;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;
    @media screen and (min-height: 700px) {
      gap: 24px;
    }
    @media screen and (min-height: 800px) {
      gap: 32px;
    }
  }
  .text-small {
    font-size: 16px;
    text-align: left;
    @media screen and (min-height: 700px) {
      font-size: 20px;
    }
    @media screen and (min-height: 800px) {
      font-size: 22px;
    }
  }
}
.center-element {
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    margin: 0 auto;
  }
}

span {
  width: 100%;
}
</style>
