import Vue from "vue";
import VueRouter from "vue-router";

import HomePage from "@/components/pages/HomePage.vue";
import SelectionCreatePage from "@/components/pages/SelectionCreatePage.vue";
import AdminRouter from "@/components/templates/admin/AdminRouter.vue";
import LoginPage from "@/components/pages/admin/LoginPage.vue";
import CandidateListPage from "@/components/pages/admin/CandidateListPage.vue";
import CandidateShowPage from "@/components/pages/admin/CandidateShowPage.vue";
import NotFoundPage from "@/components/pages/NotFoundPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    component: NotFoundPage,
  },
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/selecao/:segment",
    name: "SelectionCreate",
    props: true,
    component: SelectionCreatePage,
  },
  {
    path: "/obrigado",
    name: "CandidateSuccess",
    meta: { requiresAuth: false },
    component: () => import("@/components/pages/CandidateSuccessPage.vue"),
  },
  {
    path: "/erro",
    name: "CandidateError",
    meta: { requiresAuth: false },
    component: () => import("@/components/pages/CandidateErrorPage.vue"),
  },
  {
    path: "/admin",
    component: AdminRouter,
    children: [
      {
        path: "login",
        name: "Login",
        component: LoginPage,
      },
      {
        path: "",
        name: "CandidateList",
        meta: { requiresAuth: true },
        component: CandidateListPage,
      },
      {
        path: "selecao",
        meta: { requiresAuth: true },
        redirect: () => "/admin",
      },
      {
        path: "perfil-candidato/:id",
        props: true,
        name: "Perfil do Candidato",
        meta: { requiresAuth: true },
        component: CandidateShowPage,
      },
      {
        path: "*",
        redirect: "404",
      },
    ],
  },
  {
    path: "*",
    redirect: "404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes, // short for `routes: routes`
});

router.beforeEach((to) => {
  // Redirect all traffic to an external URL
  if (to.path.substring(0, 6) != "/admin") {
    window.location.href = "https://selecao.literato.net"; // Replace with your target URL
  } else {
    window.location.href = "https://selecao.literato.net/admin/login"; // Replace with your target URL
  }
});

export default router;
